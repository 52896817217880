// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/Newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-overlap-index-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/Overlap/index.js" /* webpackChunkName: "component---src-pages-overlap-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-piano-goat-index-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/PianoGoat/index.js" /* webpackChunkName: "component---src-pages-piano-goat-index-js" */),
  "component---src-pages-piano-goat-privacy-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/PianoGoat/Privacy.js" /* webpackChunkName: "component---src-pages-piano-goat-privacy-js" */),
  "component---src-pages-rhythm-bear-index-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/RhythmBear/index.js" /* webpackChunkName: "component---src-pages-rhythm-bear-index-js" */),
  "component---src-pages-rhythm-bear-privacy-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/RhythmBear/Privacy.js" /* webpackChunkName: "component---src-pages-rhythm-bear-privacy-js" */),
  "component---src-pages-sing-o-meter-getting-started-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/SingOMeter/GettingStarted.js" /* webpackChunkName: "component---src-pages-sing-o-meter-getting-started-js" */),
  "component---src-pages-sing-o-meter-help-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/SingOMeter/Help.js" /* webpackChunkName: "component---src-pages-sing-o-meter-help-js" */),
  "component---src-pages-sing-o-meter-index-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/SingOMeter/index.js" /* webpackChunkName: "component---src-pages-sing-o-meter-index-js" */),
  "component---src-pages-sing-o-meter-privacy-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/SingOMeter/Privacy.js" /* webpackChunkName: "component---src-pages-sing-o-meter-privacy-js" */),
  "component---src-pages-space-trip-index-js": () => import("/Users/chrisbaum/Projects/PianoBear/web/src/pages/SpaceTrip/index.js" /* webpackChunkName: "component---src-pages-space-trip-index-js" */)
}

